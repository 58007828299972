import PropTypes from "prop-types"
import React, { Fragment, useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  if (localStorage.getItem("userAuthScolarite")) {
    var userAuthScolarite = JSON.parse(
      localStorage.getItem("userAuthScolarite")
    )
    var access = userAuthScolarite.user.access
    var user_id = userAuthScolarite.user.id
  }
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Actualités")}</span>
              </Link>
            </li>

            {access[0].access == 1 ? (

              <Fragment>
                {user_id == 16 ? (
                    <Fragment>
                      <li>
                        <Link to="/Enseignant" className="">
                          <i className="fas fa-chalkboard-teacher"></i>
                          <span>{props.t("Enseignant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Grade" className="">
                          <i className="fas fa-user-graduate"></i>
                          <span>{props.t("Grade enseignant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Departement" className="">
                          <i className="fas fa-university"></i>
                          <span>{props.t("Département")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/#" className="has-arrow ">
                          <i className="fas fa-book"></i>
                          <span>{props.t("Education")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                          <li>
                            <Link to="/Module">{props.t("Modules")}</Link>
                          </li>
                          <li>
                            <Link to="/Matiere">{props.t("Matière")}</Link>
                          </li>
                          <li>
                            <Link to="/Salle">{props.t("Salle")}</Link>
                          </li>
                        </ul>
                      </li>
                    </Fragment>
                ):(
                    <Fragment>
                      <li>
                        <Link to="/Historique" className="">
                          <i className="fas fa-history"></i>
                          <span>{props.t("Historique")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Etudiant" className="">
                          <i className="fas fa-child"></i>
                          <span>{props.t("Etudiant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Enseignant" className="">
                          <i className="fas fa-chalkboard-teacher"></i>
                          <span>{props.t("Enseignant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Grade" className="">
                          <i className="fas fa-user-graduate"></i>
                          <span>{props.t("Grade enseignant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Statut" className="">
                          <i className="fas fa-user-graduate"></i>
                          <span>{props.t("Statuts enseignant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Departement" className="">
                          <i className="fas fa-university"></i>
                          <span>{props.t("Département")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/#" className="has-arrow ">
                          <i className="fas fa-list"></i>
                          <span>{props.t("Formation")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">

                          <li>
                            <Link to="/InstitutOrigin">{props.t("Institut origine")}</Link>
                          </li>
                          <li>
                            <Link to="/SpécialitésEnseignant">{props.t("Spécialités enseignants")}</Link>
                          </li>
                          <li>
                            <Link to="/Specialite">{props.t("Spécialité")}</Link>
                          </li>
                          <li>
                            <Link to="/Groupe">{props.t("Groupe")}</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#" className="has-arrow ">
                          <i className="fas fa-book"></i>
                          <span>{props.t("Education")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                          <li>
                            <Link to="/Module">{props.t("Modules")}</Link>
                          </li>
                          <li>
                            <Link to="/Matiere">{props.t("Matière")}</Link>
                          </li>
                          <li>
                            <Link to="/Salle">{props.t("Salle")}</Link>
                          </li>
                        </ul>
                      </li>
                    </Fragment>
                )}

              </Fragment>
            ) : null}
            {access[1].access == 1 ? (
              <Fragment>
                { user_id == 16 ? (
                    <Fragment>
                      <li>
                        <Link to="/SchedulesEnseignants" className="">
                          <i className="fas fa-calendar-day"></i>
                          <span>{props.t("Emploi enseignant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/PlanEtude" className="">
                          <i className="fas fa-newspaper "></i>
                          <span>{props.t("PDF / EXCEL")}</span>
                        </Link>
                      </li>
                    </Fragment>
                ):(
                    <Fragment>
                      <li>
                        <Link to="/SchedulesEnseignants" className="">
                          <i className="fas fa-calendar-day"></i>
                          <span>{props.t("Emploi enseignant")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Registre" className="">
                          <i className="fas fa-calendar-day"></i>
                          <span>{props.t("Registre")}</span>
                        </Link>
                      </li>
                    </Fragment>
                  )}

              </Fragment>
            ) : null}
            {access[2].access == 1 ? (
              <Fragment>
                {" "}
                <li>
                  <Link to="/SchedulesGroups" className="">
                    <i className="fas fa-calendar-day"></i>
                    <span>{props.t("Emploi groupes")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
            {access[3].access == 1 ? (
              <Fragment>
                {" "}
                <li>
                  <Link to="/ScheduleSalle" className="">
                    <i className="fas fa-calendar-day"></i>
                    <span>{props.t("Emploi salle")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/PlanEtude" className="">
                    <i className="fas fa-newspaper "></i>
                    <span>{props.t("PDF / EXCEL")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
            {access[4].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/Rattrapage" className="">
                    <i className="fas fa-info-circle"></i>
                    <span>{props.t("Avis de rattrapage")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
            {access[5].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/AvisTest" className="">
                    <i className="fas fa-info-circle"></i>
                    <span>{props.t("Avis de DS")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
            {access[6].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/NoteDS" className="">
                    <i className="fas fa-pen"></i>
                    <span>{props.t("Note DS")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/NoteDS2" className="">
                    <i className="fas fa-pen"></i>
                    <span>{props.t("Note DS2")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
            {/* {access[7].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/ListAbsence" className="">
                    <i className="fas fa-chart-bar"></i>
                    <span>{props.t("Absence")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null} */}
            {access[8].access == 1 ? (
              <Fragment>
                {user_id != 17 ? (
                <li>
                  <Link to="/CalDS2" className="">
                    <i className="fas fa-fas fa-calendar-alt"></i>
                    <span>{props.t("Calendrier DS2")}</span>
                  </Link>
                </li>
                ) : null}
              </Fragment>
            ) : null}
            {access[8].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/CalExam" className="">
                    <i className="fas fa-calendar-alt"></i>
                    <span>{props.t("Calendrier examen")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
            {access[9].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/Surveillance" className="">
                    <i className="fas fa-eye"></i>
                    <span>{props.t("Surveillance")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
            {access[11].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="fas fa-edit"></i>
                    <span>{props.t("Compostage")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/Compostage">{props.t("Principale")}</Link>
                    </li>
                    <li>
                      <Link to="/CompostageCon">{props.t("Contrôle")}</Link>
                    </li>
                  </ul>
                </li>
                {user_id != 17 ? (
                    <li>
                      <Link to="/QuartExamen" className="">
                        <i className="fas fa-calendar-times"></i>
                        <span>{props.t("Quart Examen")}</span>
                      </Link>
                    </li>
                ):(null)}
              </Fragment>
            ) : null}
            {access[12].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="fas fa-pen"></i>
                    <span>{props.t("Note examen")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/NoteExamen">{props.t("Principale")}</Link>
                    </li>
                    <li>
                      <Link to="/NoteExamenCon">{props.t("Contrôle")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/ResultatExamen" className="">
                    <i className="fas fa-file-export"></i>
                    <span>{props.t("Resultat d' examen")}</span>
                  </Link>
                </li>
                  {/*<li>*/}
                  {/*  <Link to="/#" className="has-arrow ">*/}
                  {/*    <i className="fas fa-file-export"></i>*/}
                  {/*    <span>{props.t("Resultat d' examen")}</span>*/}
                  {/*  </Link>*/}
                  {/*  <ul className="sub-menu" aria-expanded="false">*/}
                  {/*    <li>*/}
                  {/*      <Link to="/ResultatExamen">{props.t("Principale")}</Link>*/}
                  {/*    </li>*/}
                  {/*    /!*<li>*!/*/}
                  {/*    /!*  <Link to="/ResultatExamenControle">{props.t("Contrôle")}</Link>*!/*/}
                  {/*    /!*</li>*!/*/}
                  {/*  </ul>*/}
                  {/*</li>*/}
                </Fragment>
            ) : null}
            {access[13].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="fas fa-sticky-note"></i>
                    <span>{props.t("Demande en ligne")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/AttestationPresence">
                        {props.t("Attestation de présence")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/AttestationPresenceArchive">
                        {props.t("Archive Attestation de présence")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </Fragment>
            ) : null}
            {access[14].access == 1 ? (
              <Fragment>
                <li>
                  <Link to="/Tirage" className="">
                    <i className="fas fa-print"></i>
                    <span>{props.t("Demande Tirage")}</span>
                  </Link>
                </li>
              </Fragment>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
