import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import AddInstitutOrigin from "./AddInstitutOrigin"
import DataTableInstitutOrigin from "./DataTableInstitutOrigin";

const InstitutOrigin = props => {
    const [section, setSection] = useState(1)

    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableInstitutOrigin/>
        } else if (section === 2) {
            return <AddInstitutOrigin setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Institut origin | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    Institut Origine
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 2,
                                    })}
                                    onClick={() => {
                                        setSection(2)
                                    }}
                                >
                                    Ajouter Institut Origine
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(InstitutOrigin)
