import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap"
import {withRouter} from "react-router"
import {Link} from "react-router-dom"

const DataTableSpecialiteEnseignant = props => {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    // Data Table
    const [orders, setOrders] = useState([])

    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "name",
            text: "Spécialité enseignant",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "Modification",
            isDummyField: true,
            text: "Modification",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (

                <div style={{display: "flex", justifyContent: "center"}}>
                    <Link to={"/EditSpécialitésEnseignant?id=" + row.id}>
                        <i
                            style={{color: "cornflowerblue", cursor: "pointer"}}
                            className="fas fa-edit"
                        ></i>
                    </Link>
                </div>


            ),
        },
        {
            dataField: "Suppression",
            isDummyField: true,
            text: "Suppression",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) =>
                row.count == 0 ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            style={{ color: "#ED6464", cursor: "pointer" }}
                            onClick={() => toggle(row.id)}
                            className="fas fa-trash-alt"
                        ></i>
                    </div>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            style={{ color: "#a7a3a3", cursor: "pointer" }}
                            className="fas fa-trash-alt"
                            title="Ce grade déja utilisé"
                        ></i>
                    </div>
                ),
        },
    ]

    const {SearchBar} = Search

    useEffect(async () => {

        const res = await API.get("matiere/origine/list").then(res => {
            setOrders(res.data.MatiereOrigine)
        })
        setLoading(true)
    }, [])

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }

    useEffect(() => {
        setModal(false)
    }, [orders])

    const toggleDelete = async () => {
        const res = await API.post("matiere/origine/delete", {
            id: id,
        }).then(res => {
            const resD = API.get("matiere/origine/list").then(resD => {
                setOrders(resD.data.MatiereOrigine)
            })
            setLoading(true)
        })
    }
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{ width: "100% !important", textAlign: "center !important" }}
                    toggle={toggle}
                    tag="h4"
                >
                    {"Suppression de spécialité enseignant"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>
                                {"Voulez-vous supprimer cette spécialité enseignant ?"}

                            </p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(DataTableSpecialiteEnseignant)
DataTableSpecialiteEnseignant.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
}
